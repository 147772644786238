<template>
    <div id="services">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <HeadOrders
    :PathPageFrom="$t('Home')"
    :PathPageCenter="$t('Services')"
    :PathPageTo="$t('Order_Detials')"
    :pathFrom="`/`"/>
    <div class="container">
    <Details />
    </div>
  </div>
  </template>
  <script>
  import { defineAsyncComponent } from 'vue'
  export default {
  name: 'Services Page',
  components: {
    HeadOrders: defineAsyncComponent( () => import('@/components/Global/HeadOrders.vue') ),
    Details: defineAsyncComponent( () => import('@/components/Services/Details.vue') ),
  },
  }
  </script>



